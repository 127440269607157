<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    data-template="content-page"
  />
</template>

<script>
  import { AlchemyPage } from "~/mixins/alchemyVue"
  import { useNotificationStore } from "~/pinia/notifications"

  const NARROW_ELEMENTS = ["text_block", "headline"]

  export default {
    components: {
      headline: defineAsyncComponent(
        () => import("~/alchemy/elements/headline.vue"),
      ),
      text_block: defineAsyncComponent(
        () => import("~/alchemy/elements/text_block.vue"),
      ),
      divider: defineAsyncComponent(
        () => import("~/components/Divider/index.vue"),
      ),
      wick_guide: defineAsyncComponent(
        () => import("~/alchemy/elements/wick_guide"),
      ),
      room_spray_dilution_calculator: defineAsyncComponent(
        () => import("~/alchemy/elements/room_spray_dilution_calculator.vue"),
      ),
      material_supply_calculator: defineAsyncComponent(
        () => import("~/alchemy/elements/material_supply_calculator.vue"),
      ),
    },
    mixins: [AlchemyPage],
    setup(props) {
      const config = useRuntimeConfig()
      useHead(usePageInfo(props.page))
      useHead({
        script: [
          {
            type: "application/ld+json",
            innerHTML: {
              "@context": "https://schema.org",
              "@type": "Article",
              name: `CandleScience - ${props.page.name}`,
              url: `${config.public.BASE_URL}${props.page.urlPath}`,
            },
          },
        ],
      })
      const notificationStore = useNotificationStore()
      onMounted(() => {
        notificationStore.announce(props.page.title)
      })
    },
    data() {
      return {
        NARROW_ELEMENTS,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
